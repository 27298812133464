/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as textStyles from 'styles/helpers/text'
import Wrapper from 'components/common/Wrapper'

const sizeHierarchy = {
  XL: 'h1',
  L: 'h2',
  M: 'h3',
  S: 'h4',
  XS: 'h5',
}

const StyledH = styled(({ tag, size, ...props }) => {
  const element = tag || sizeHierarchy[size] || 'h6'
  return (
    <Wrapper
      omitProps={{
        keys: ['color'],
      }}
      tag={element}
      bumpSpecificity
      {...props}
    />
  )
})`
  ${({ size }) => textStyles[`heading${size || 'XS'}`]}

  ${({ color }) => color && `color: ${color};`}
`

const H = ({ children, ...props }) => <StyledH {...props}>{children}</StyledH>

H.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['XL', 'L', 'M', 'S', 'XS']),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
}

StyledH.propTypes = H.propTypes

export default H
