import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { COLOR, GTR } from 'styles/tokens'
import { screenMax, screenMin } from 'styles/helpers/responsive'

import Icon from 'components/Icon'

import ROUTES from 'routes'

import { useGlobalContext } from 'context'

const StyledTextWrapper = styled.span`
  color: ${COLOR.WHITE};

  ${({ $light }) => $light && `color: ${COLOR.GREY.DARK}`};

  ${screenMax.m`color: ${COLOR.GREY.DARK}`}
`

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${COLOR.ACCENT.PRIMARY};

  &:hover {
    text-decoration: none;

    ${StyledTextWrapper} {
      ${screenMin.l`
        color: ${COLOR.ACCENT.PRIMARY};
      `}
    }
  }

  ${screenMax.m`
      svg {
        fill: ${COLOR.WHITE};
      }
    `}
`

const StyledIcon = styled(Icon.Heart)`
  margin-right: ${GTR.XS};
`

const StyledFullIcon = styled(Icon.FullHeart)`
  margin-right: ${GTR.XS};
`

const SavedJobs = ({ light }) => {
  const { savedJobs } = useGlobalContext()
  const numSavedJobs = Object.keys(savedJobs).length
  const hasSavedJobs = numSavedJobs > 0

  const jobOrJobs = numSavedJobs === 1 ? 'job' : 'jobs'

  return (
    <StyledLink to={ROUTES.SAVED_JOBS.PATH} $light={light}>
      {hasSavedJobs ? <StyledFullIcon width={24} /> : <StyledIcon width={24} />}
      <StyledTextWrapper $light={light}>
        {numSavedJobs ? `${numSavedJobs} saved ${jobOrJobs}` : 'Saved jobs'}
      </StyledTextWrapper>
    </StyledLink>
  )
}

SavedJobs.propTypes = {
  light: PropTypes.bool,
}

SavedJobs.defaultProps = {
  light: false,
}

export default SavedJobs
