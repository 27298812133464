import React from 'react'
import { Helmet } from 'react-helmet'
import { FONT } from 'styles/tokens'

import AzoSansRegularEOT from 'fonts/AzoSans-Regular.eot'
import AzoSansRegularTTF from 'fonts/AzoSans-Regular.ttf'
import AzoSansRegularWOFF from 'fonts/AzoSans-Regular.woff'
import AzoSansRegularWOFF2 from 'fonts/AzoSans-Regular.woff2'

import AzoSansMediumEOT from 'fonts/AzoSans-Medium.eot'
import AzoSansMediumTTF from 'fonts/AzoSans-Medium.ttf'
import AzoSansMediumWOFF from 'fonts/AzoSans-Medium.woff'
import AzoSansMediumWOFF2 from 'fonts/AzoSans-Medium.woff2'

import AzoSansBoldEOT from 'fonts/AzoSans-Bold.eot'
import AzoSansBoldTTF from 'fonts/AzoSans-Bold.ttf'
import AzoSansBoldWOFF from 'fonts/AzoSans-Bold.woff'
import AzoSansBoldWOFF2 from 'fonts/AzoSans-Bold.woff2'

const fontImports = `
  @font-face {
    font-family: 'Azo Sans';
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-style: normal;
    src:
      url(${AzoSansRegularWOFF2}) format('woff2'),
      url(${AzoSansRegularWOFF}) format('woff'),
      url(${AzoSansRegularTTF}) format('truetype'),
      url(${AzoSansRegularEOT}) format('embedded-opentype');
    unicode-range: U+000-5FF;
    font-display: fallback;
  }

    @font-face {
    font-family: 'Azo Sans';
    font-weight: ${FONT.WEIGHT.MEDIUM};
    font-style: normal;
    src: 
      url(${AzoSansMediumWOFF2}) format('woff2'),
      url(${AzoSansMediumWOFF}) format('woff'),
      url(${AzoSansMediumTTF}) format('truetype'),
      url(${AzoSansMediumEOT}) format('embedded-opentype');
    unicode-range: U+000-5FF;
    font-display: fallback;
  }

    @font-face {
    font-family: 'Azo Sans';
    font-weight: ${FONT.WEIGHT.BOLD};
    font-style: normal;
    src: 
      url(${AzoSansBoldWOFF2}) format('woff2'),
      url(${AzoSansBoldWOFF}) format('woff'),
      url(${AzoSansBoldTTF}) format('truetype'),
      url(${AzoSansBoldEOT}) format('embedded-opentype');
    unicode-range: U+000-5FF;
    font-display: fallback;
  }
`
const FontStylesheet = () => (
  <>
    <Helmet>
      <style type="text/css">{fontImports}</style>
    </Helmet>
  </>
)

export default FontStylesheet
