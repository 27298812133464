import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import responsiveCssProps from 'styles/helpers/responsiveProps'
import { GTR } from 'styles/tokens'

import Wrapper from 'components/common/Wrapper'

const formatGap = (value) =>
  typeof value === 'number' ? `${value}px` : GTR[value] || value

const dividerOutput = ({ columns, dividerSize }) => (values) => {
  const inserts = values.sort((a, b) => b - a) // sort in decreasing order
  const templateColumns = Array.from({ length: columns }, () => '1fr')
  inserts.forEach((insert) => templateColumns.splice(insert, 0, dividerSize))
  return `grid-template-columns: ${templateColumns.join(' ')};`
}

const StyledGrid = styled((props) => (
  <Wrapper
    omitProps={{
      keys: ['columns', 'dividerSize'],
      responsiveKeys: ['gap', 'dividerAtColumn'],
    }}
    // eslint-disable-next-line
    {...props}
  />
))`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  ${(props) =>
    responsiveCssProps({
      props: Object.assign(
        props.container
          ? {
              gapFromM: 'L',
              gapFromL: 'XL',
            }
          : {},
        props
      ),
      propName: 'gap',
      cssProperty: 'grid-gap',
      formatValues: formatGap,
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'dividerAtColumn',
      joinValues: (values) => (typeof values === 'object' ? values : [values]),
      output: dividerOutput(props),
    })}
`

const Grid = ({ children, ...props }) => (
  // eslint-disable-next-line
  <StyledGrid {...props}>{children}</StyledGrid>
)

Grid.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number,
  /** Used alongside dividerAtColumn prop */
  dividerSize: PropTypes.string,
}

Grid.defaultProps = {
  columns: 12,
  gap: 'M',
  dividerSize: '1px',
}

StyledGrid.propTypes = Grid.propTypes
StyledGrid.defaultProps = Grid.defaultProps

export default Grid
