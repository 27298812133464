export const FAMILY = {
  BODY: 'Azo Sans, Helvetica, sans-serif',
}

export const SIZE = {
  XS: '12px',
  S: '15px',
  M: '17px',
  L: '20px',
  XL: '26px',
  XXL: '36px',
  XXXL: '52px',
}

export const WEIGHT = {
  REGULAR: 400,
  MEDIUM: 600,
  BOLD: 700,
}

export default {
  FAMILY,
  SIZE,
  WEIGHT,
}
