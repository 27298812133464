import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import {
  getStore as getCookie,
  store as storeCookie,
} from 'lib/storage/cookies'

import Icon from 'components/Icon'
import { Button, Wrapper } from 'components/common'
import { COLOR, GTR, BORDER, FONT } from 'styles/tokens'

import PATHS from 'routes/paths'

const StyledCookieWrapper = styled(Wrapper)`
  position: fixed;
  bottom: ${GTR.S};
  right: 0;
  left: 0;
  z-index: 300;
`

const StyledInner = styled(Wrapper)`
  background-color: ${COLOR.BACKGROUND.PALE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLOR.GREY.LIGHT};

  p {
    font-size: ${FONT.SIZE.S};
    margin-bottom: 0;
    margin-right: ${GTR.M};
    transform: translateY(2px);
  }
`

const StyledIconCross = styled(Icon.Cross)`
  min-width: 14px;
`

const StyledIconCookie = styled(Icon.Cookie)`
  margin-right: ${GTR.M};
  min-width: 35px;
`

const StyledButton = styled(Button)`
  && {
    padding: ${GTR.XS};
  }
`

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    setShowCookieBanner(getCookie('ppCookieBannerHidden') === undefined)
  }, [])

  const closeCookieBanner = () => {
    setShowCookieBanner(false)
    storeCookie('ppCookieBannerHidden', true)
  }

  if (!showCookieBanner) return null
  return (
    <StyledCookieWrapper>
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledInner>
          <StyledIconCookie width={35} />
          <p>
            We use cookies. By using our website, you agree to our use of
            cookies in accordance with our{' '}
            <Link to={PATHS.COOKIES}>Cookies Policy</Link>.
          </p>
          <StyledButton type="button" onClick={closeCookieBanner}>
            <StyledIconCross width={14} />
          </StyledButton>
        </StyledInner>
      </Wrapper>
    </StyledCookieWrapper>
  )
}

export default CookieBanner
