/* eslint-disable */

import { css } from 'styled-components'
import { screenMin } from 'styles/helpers/responsive'

export const responsiveCssPropNames = (propName) => [
  propName,
  `${propName}FromS`,
  `${propName}FromM`,
  `${propName}FromL`,
  `${propName}FromXL`,
]

const responsiveCssProps = ({
  props,
  propName,
  cssProperty,
  formatValues,
  joinValues,
  output,
}) => {
  const formatValuesFunc = formatValues ? formatValues : (v) => v
  const joinValuesFunc = joinValues
    ? joinValues
    : (values) =>
        typeof values === 'object'
          ? values.map(formatValuesFunc).join(' ')
          : formatValuesFunc(values)

  const outputFunc = output
    ? output
    : (value, property) => `
    ${property}: ${value};
  `

  const outputCss = (values) => {
    return (
      values !== undefined &&
      values !== null &&
      outputFunc(joinValuesFunc(values), cssProperty)
    )
  }

  const propNames = responsiveCssPropNames(propName)

  const valueXS = props[propNames[0]]
  const valueS = props[propNames[1]]
  const valueM = props[propNames[2]]
  const valueL = props[propNames[3]]
  const valueXL = props[propNames[4]]

  if (
    valueXS === undefined &&
    valueS === undefined &&
    valueM === undefined &&
    valueL === undefined &&
    valueXL === undefined
  )
    return null

  return css`
    ${outputCss(valueXS)}
    ${typeof valueS !== 'undefined' &&
      screenMin.s`
      ${outputCss(valueS)}
    `}
    ${typeof valueM !== 'undefined' &&
      screenMin.m`
      ${outputCss(valueM)}
    `}
    ${typeof valueL !== 'undefined' &&
      screenMin.l`
      ${outputCss(valueL)}
    `}
    ${typeof valueXL !== 'undefined' &&
      screenMin.xl`
      ${outputCss(valueXL)}
    `}
  `
}

export default responsiveCssProps
