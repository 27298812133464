export const COLOR = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',

  ACCENT: {
    PRIMARY: '#32DAC3',
    PRIMARY_LIGHTER: '#60E5CB',
    SECONDARY: '#333E55',
  },

  STATE: {
    HIGHLIGHT: '#dbf6f0',
    ERROR: '#DB135B',
  },

  BACKGROUND: {
    PALE: '#F7F7F7',
  },

  GREY: {
    LIGHT: '#E6E6E6',
    MEDIUM: '#A39FA8',
    DARK_LIGHTER: '#302B36',
    DARK: '#2A2630',
  },
}

export default COLOR
