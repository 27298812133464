import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import iconPaths from './paths'

const StyledIcon = styled.svg`
  fill: currentColor;
`

const Icon = ({ width, height, viewBox, alt, children, className }) => (
  <StyledIcon
    width={width || (!height && '100%') || height}
    height={height}
    viewBox={viewBox}
    aria-hidden
    alt={alt}
    className={className}
  >
    {children}
  </StyledIcon>
)

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alt: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Icon.defaultProps = {
  className: '',
  width: null,
  height: null,
}

iconPaths.forEach((preset) => {
  const PresetIcon = ({ alt, width, height, className }) => (
    <Icon
      alt={alt || `${preset.name} icon`}
      viewBox={preset.viewBox}
      width={width}
      height={height}
      className={className}
    >
      {preset.paths}
    </Icon>
  )

  PresetIcon.propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  PresetIcon.defaultProps = {
    className: '',
    alt: '',
    width: null,
    height: null,
  }

  Icon[preset.key] = PresetIcon
})

export default Icon
