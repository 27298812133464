export const GTR = {
  XXS: '4px',
  XS: '8px',
  S: '16px',
  M: '24px',
  L: '32px',
  XL: '48px',
  XXL: '72px',
}

export const CONTENT_OUTER_WIDTH = '1196px'
export const CONTENT_INNER_WIDTH = '1100px'

export const BREAKPOINT = {
  XS: 300,
  S: 648,
  M: 964,
  L: 1296,
}
