import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import CurveWrapper from 'components/CurveWrapper'
import { Grid, H, Wrapper } from 'components/common'
import { COLOR, FONT, GTR } from 'styles/tokens'
import { screenMax, screenMin } from 'styles/helpers/responsive'
import Icon from 'components/Icon'

import PATHS from 'routes/paths'

const StyledFooter = styled(CurveWrapper)`
  color: ${COLOR.WHITE};

  ${screenMax.s`
    font-size: ${FONT.SIZE.S};
  `}
`

const StyledInlineList = styled.ul`
  ${screenMin.m`
    display: flex;
  `}
`

const LinkStyles = css`
  color: ${COLOR.WHITE};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${COLOR.ACCENT.PRIMARY};
  }
`

const StyledListItem = styled.li`
  ${LinkStyles}

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`

const StyledLink = styled(Link)`
  ${LinkStyles}
`

const StyledSocialLink = styled.a`
  ${LinkStyles}

  display: flex;
  align-items: center;

  font-size: 0;

  &:hover {
    color: ${COLOR.ACCENT.PRIMARY};
  }
`

const StyledLinkHeading = styled(H)`
  margin-bottom: ${GTR.M};
`

const StyledP = styled.p`
  color: ${COLOR.WHITE};

  ${screenMax.s`
    font-size: ${FONT.SIZE.S};
  `}
`

const StyledA = styled.a`
  ${LinkStyles}
`

const StyledInlineListItem = styled.li`
  &:not(:last-of-type) {
    margin: 0 0 ${GTR.XS};

    ${screenMin.m`
      margin: 0 ${GTR.L} 0 0;
    `}
  }
`

const StyledPhoneIcon = styled(Icon.Phone)`
  margin-right: ${GTR.XS};
  transform: translateY(6px);

  ${screenMax.s`
    width: 16px;
    transform: translateY(3px);
  `}
`

const StyledBottomLinks = styled(Wrapper)`
  display: flex;
  align-self: center;

  ${screenMax.s`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  `}
`

const StyledTelephoneLink = styled.a`
  ${LinkStyles}

  ${screenMin.l`
    display: block;
    transform: translateY(-3px);
  `}
`

const StyledSocialWrapper = styled.ul`
  display: flex;
  margin-right: ${GTR.L};
  margin-bottom: 0;

  ${screenMax.s`
    margin: 0 0 6px ${GTR.M};
  `}
`

const StyledInlineSocialItem = styled.li`
  display: flex;

  &:not(:last-of-type) {
    margin: 0 ${GTR.L} 0 0;
  }

  ${screenMax.s`
    &:not(:last-of-type) {
      margin: 0 ${GTR.M} 0 0;
    }

    svg {
      width: 16px;
    }
  `}
`

const StyledTwitterIcon = styled(Icon.Twitter)`
  position: relative;
  // top: 1px;
`

const Footer = () => {
  return (
    <StyledFooter top={33}>
      <Wrapper container>
        <Grid>
          <Grid.Item spanFromL={4}>
            <Grid>
              <Grid.Item span={6}>
                <StyledLinkHeading size="S">Company</StyledLinkHeading>
                <ul>
                  <StyledListItem>
                    <StyledLink to={PATHS.ABOUT}>About us</StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink to={PATHS.JOIN_OUR_TEAM}>
                      Join our team
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink to={PATHS.PIXEL_PRIDE}>Pixel Pride</StyledLink>
                  </StyledListItem>
                  {/* <StyledListItem>
                    <StyledLink to={PATHS.BLOG}>Blog</StyledLink>
                  </StyledListItem> */}
                </ul>
              </Grid.Item>
              <Grid.Item span={6}>
                <StyledLinkHeading size="S">Our services</StyledLinkHeading>
                <ul>
                  <StyledListItem>
                    <StyledLink to={PATHS.JOBS}>Search jobs</StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink to={PATHS.REGISTER}>
                      Register with us
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink to={PATHS.EMPLOYERS}>
                      Employer’s guide
                    </StyledLink>
                  </StyledListItem>
                </ul>
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item spanFromL={3} startColumnFromL={10}>
            <StyledLinkHeading size="S">Contact us</StyledLinkHeading>
            <StyledP margin={[0, 0, 'M']}>
              Unit 78
              <br />
              Containerville
              <br />
              35 Corbridge Crescent
              <br />
              London
              <br />
              E2 9DT
            </StyledP>
            <StyledA href="mailto:hello@pixel-pond.com">
              hello@pixel-pond.com
            </StyledA>
          </Grid.Item>
        </Grid>
        <Grid separator>
          <Grid.Item spanFromL={3} startColumnFromL={10}>
            <StyledTelephoneLink href="tel:+442032394462">
              <StyledPhoneIcon width={20} />
              <span> +44 (0) 203 239 4462</span>
            </StyledTelephoneLink>
          </Grid.Item>
          <Grid.Item
            spanFromL={8}
            startColumnFromL={1}
            style={{ display: 'flex' }}
          >
            <StyledBottomLinks>
              <StyledSocialWrapper>
                <StyledInlineSocialItem>
                  <StyledSocialLink
                    href="https://www.linkedin.com/company/pixelpondrecruitment"
                    target="_blank"
                  >
                    <Icon.LinkedIn width={21} height={22} />
                    LinkedIn
                  </StyledSocialLink>
                </StyledInlineSocialItem>
                <StyledInlineSocialItem>
                  <StyledSocialLink
                    href="https://twitter.com/PixelPondLife"
                    target="_blank"
                  >
                    <StyledTwitterIcon width={26} height={26} />
                    Twitter
                  </StyledSocialLink>
                </StyledInlineSocialItem>
                <StyledInlineSocialItem>
                  <StyledSocialLink
                    href="https://www.instagram.com/thepixelpondlife"
                    target="_blank"
                  >
                    <Icon.Instagram width={22} height={22} />
                    Instagram
                  </StyledSocialLink>
                </StyledInlineSocialItem>
              </StyledSocialWrapper>
              <StyledInlineList>
                <StyledInlineListItem>
                  <StyledLink to={PATHS.TERMS}>Terms</StyledLink>
                </StyledInlineListItem>
                <StyledInlineListItem>
                  <StyledLink to={PATHS.PRIVACY}>Privacy</StyledLink>
                </StyledInlineListItem>
                <StyledInlineListItem>
                  <StyledLink to={PATHS.COOKIES}>Cookies</StyledLink>
                </StyledInlineListItem>
                <StyledInlineListItem>© Pixel Pond Ltd</StyledInlineListItem>
              </StyledInlineList>
            </StyledBottomLinks>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
