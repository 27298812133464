import { responsiveCssPropNames } from 'styles/helpers/responsiveProps'

const filterProps = ({ props, keys = [], responsiveKeys = [] }) => {
  const filteredProps = {}
  const keysToRemove = responsiveKeys
    .map(responsiveCssPropNames)
    .reduce((acc, cur) => acc.concat(cur), [])
    .concat(keys)

  Object.keys(props).forEach((key) => {
    if (!keysToRemove.includes(key)) filteredProps[key] = props[key]
  })

  return filteredProps
}

export default filterProps
