import chroma from 'chroma-js'

export const darken = (color, degree) =>
  chroma(color)
    .darken(degree)
    .hex()

export const brighten = (color, degree) =>
  chroma(color)
    .brighten(degree)
    .hex()

export const alpha = (color, opacity) =>
  chroma(color)
    .alpha(opacity)
    .css()
