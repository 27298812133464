import { createGlobalStyle } from 'styled-components'
import { COLOR, FONT, GTR } from './tokens'
import {
  paragraph,
  headingXL,
  headingL,
  headingM,
  headingS,
  headingXS,
} from './helpers/text'
import reset from './reset'

const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    width: 100%;
  }
  body {
    overflow-x: hidden;
  }
  ::selection {
    background-color: ${COLOR.STATE.HIGHLIGHT};
  }
  
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type='number'] {
    -moz-appearance: textfield;
  }
  
  textarea,
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='submit'] {
    -webkit-appearance: none;
    box-shadow: none;
  }
  
  button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    &:focus {
      outline: 1px dashed ${COLOR.ACCENT.SECONDARY};
    }
    &:active {
      outline: 1px dashed ${COLOR.ACCENT.PRIMARY};
    }
  }
  
  body,
  input,
  textarea,
  select {
    font-family: ${FONT.FAMILY.BODY};
    font-size: ${FONT.SIZE.M};
    font-weight: normal;
    line-height: 1.7;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    color: ${COLOR.GREY.DARK};
  }
  
  p {
    ${paragraph}
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 1px dashed ${COLOR.ACCENT.SECONDARY};
    }
    &:active {
      outline: 1px dashed ${COLOR.ACCENT.PRIMARY};
    }
  }
  h1 {
    ${headingXL}
  }
  h2 {
    ${headingL}
  }
  h3 {
    ${headingM}
  }
  h4 {
    ${headingS}
  }
  h5 {
    ${headingXS}
  }
  ol,
  ul {
    margin-bottom: ${GTR.S};
  }
  svg {
    fill: currentColor;
  }
`

export default GlobalStyle
