export default {
  404: { PATH: '/404', VIEW: '404' },
  ABOUT: { PATH: `/about`, VIEW: 'About/index' },
  // BLOG: { PATH: `/blog`, VIEW: 'Blog/index' },
  CONTACT: { PATH: `/contact`, VIEW: 'Contact/index' },
  CODE_OF_CONDUCT: { PATH: `/code-of-conduct`, VIEW: 'CodeOfConduct/index' },
  COOKIES: { PATH: `/cookies`, VIEW: 'Cookies/index' },
  EMPLOYERS: { PATH: `/employers`, VIEW: 'Employers/index' },
  HOME: { PATH: '/', VIEW: 'Home/index' },
  JOBS: { PATH: '/jobs', VIEW: 'SearchJobs/index' },
  JOIN_OUR_TEAM: { PATH: '/join-our-team', VIEW: 'JoinOurTeam/index' },
  PIXEL_PRIDE: { PATH: '/pixel-pride', VIEW: 'PixelPride/index' },
  PRIVACY: { PATH: '/privacy', VIEW: 'Privacy/index' },
  REGISTER: { PATH: '/register', VIEW: 'Register/index' },
  SAVED_JOBS: { PATH: '/saved', VIEW: 'SavedJobs' },
  SUBMITTED: {
    PATH: '/successful-application',
    VIEW: 'Submitted/index',
  },
  TERMS: { PATH: '/terms', VIEW: 'Terms' },
}
