import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Wrapper } from 'components/common'
import { COLOR, GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const COLORS = {
  [COLOR.ACCENT.PRIMARY]: COLOR.ACCENT.PRIMARY_LIGHTER,
  [COLOR.GREY.DARK]: COLOR.GREY.DARK_LIGHTER,
  [COLOR.WHITE]: COLOR.BACKGROUND.PALE,
  [COLOR.BACKGROUND.PALE]: COLOR.WHITE,
}

const StyledBackground = styled.div`
  background-color: ${({ $background }) => COLORS[$background] || $background};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -3;

  ${screenMax.s`
    background-color: ${({ $background }) => $background};
  `}
`

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledInnerWrapper = styled(Wrapper)`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ $background }) => $background};
    left: -100px;
    right: -100px;
    width: calc(100% + 200px);
    height: 120px;
    border-radius: 0 0 100% 100%;
    z-index: -1;

    ${({ $top }) => `top: calc(${$top}%);`}

    ${screenMax.s`
      display: none;
    `}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ $background }) => $background};
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;

    ${({ $top }) => `height: ${$top}%;`}

    ${screenMax.s`
      display: none;
    `}
  }
`

const CurveWrapper = ({ children, top, background, className }) => {
  return (
    <StyledWrapper className={className}>
      <StyledBackground $background={background} />
      <StyledInnerWrapper $top={top} $background={background}>
        {children}
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

CurveWrapper.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  top: PropTypes.number,
}

CurveWrapper.defaultProps = {
  background: COLOR.GREY.DARK,
  children: null,
  className: '',
  top: 50,
}

const Standalone = styled(CurveWrapper)`
  height: 120px;
  margin-top: -30px;

  ${screenMax.s`
    height: ${GTR.L};
    margin-top: 0;
  `}
`

CurveWrapper.Standalone = Standalone

export default CurveWrapper
