import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import FontStylesheet from 'styles/fonts'
import BaseStylesheet from 'styles/base'

import { getStore } from 'lib/storage/local-storage'
import { useGlobalContext } from 'context'

import CookieBanner from 'components/CookieBanner'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, light }) => {
  const { setSavedJobs } = useGlobalContext()

  useEffect(() => {
    const savedJobs = getStore('ppSavedJobs')
    setSavedJobs(savedJobs || {})
  }, [])

  return (
    <>
      <FontStylesheet />
      <BaseStylesheet />
      <CookieBanner />
      <Header light={light} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
}

Layout.defaultProps = {
  light: false,
}

export default Layout
