import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { COLOR, BORDER, GTR, FONT } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

export const spin = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`

const StyledButtonBase = styled(
  ({ loading, stretch, stretchMobile, wide, tag, ...props }) => {
    let Element = 'button'
    if (tag) {
      Element = tag
    } else if (props.href) {
      Element = 'a'
    } else {
      Element = 'button'
    }
    // eslint-disable-next-line
    return <Element {...props} />
  }
)`
  margin: 0;
  border: 0;
  outline: 0;
  padding: ${GTR.XXS} ${GTR.L} 0;
  min-height: 59px;
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-align: center;
  border-radius: ${BORDER.RADIUS.S};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.1s ease-in, color 0.2s linear;
  background-clip: border-box;
  border: 2px solid transparent;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: 0;
  }

  &:disabled {
    pointer-events: none;
    user-select: none;
  }

  ${({ stretch }) => stretch && `width: 100%;`}

    ${({ wide }) =>
      wide &&
      `
    width: 100%;
    max-width: 300px;
  `}

  ${screenMax.s`
    ${({ stretchMobile }) =>
      stretchMobile &&
      `
      width: 100%;
      max-width: 100%;
    `}
  `}

  ${({ disabled, loading }) =>
    disabled &&
    !loading &&
    `
    &:disabled {
      color: ${COLOR.GREY.MEDIUM};
      background: ${COLOR.GREY.LIGHT};
    }
  `}

  ${({ loading }) =>
    loading &&
    css`
      position: relative;
      && {
        color: transparent;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px;
        width: 24px;
        height: 24px;
        animation: ${spin} 0.8s linear infinite;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20'%3E%3Cpath d='M20 10h-2c0 4.4-3.6 8-8 8v2c5.5 0 10-4.5 10-10z'/%3E%3Cdefs%3E%3Cpath id='a' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='9' y1='11' x2='19' y2='1' gradientTransform='rotate(90 15 6)'%3E%3Cstop offset='.1' stop-opacity='.5'/%3E%3Cstop offset='.9'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23b)' fill='url(%23c)' d='M10 10V0h10v10z'/%3E%3Cg%3E%3Cdefs%3E%3Cpath id='d' d='M10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z'/%3E%3C/defs%3E%3CclipPath id='e'%3E%3Cuse xlink:href='%23d' overflow='visible'/%3E%3C/clipPath%3E%3ClinearGradient id='f' gradientUnits='userSpaceOnUse' x1='0' y1='10' x2='10' y2='0'%3E%3Cstop offset='.05' stop-opacity='0'/%3E%3Cstop offset='.9' stop-opacity='.5'/%3E%3C/linearGradient%3E%3Cpath clip-path='url(%23e)' fill='url(%23f)' d='M0 0h10v10H0z'/%3E%3C/g%3E%3C/svg%3E");
      }
    `}
`

const ButtonBase = ({ arrow, children, disabled, loading, ...props }) => {
  return (
    <StyledButtonBase
      disabled={disabled || loading}
      loading={loading}
      {...props} // eslint-disable-line
    >
      {children}
    </StyledButtonBase>
  )
}

ButtonBase.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  // eslint-disable-next-line
  href: PropTypes.string,
  // eslint-disable-next-line
  onClick: PropTypes.func,
  stretch: PropTypes.bool,
  stretchMobile: PropTypes.bool,
  // eslint-disable-next-line
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  // eslint-disable-next-line
  to: PropTypes.string,
  wide: PropTypes.bool,
}

ButtonBase.defaultProps = {
  arrow: false,
  disabled: false,
  loading: false,
  stretch: false,
  stretchMobile: false,
  wide: false,
}

export default ButtonBase
