import React from 'react'
import styled from 'styled-components'

import { brighten, alpha } from 'styles/helpers/color'
import { COLOR, FONT, GTR } from 'styles/tokens'
import ButtonBase from './base'

const buttonStyles = (
  color,
  stateBrightenDegree,
  stateShadowOpacity,
  stateProperty = 'background-color'
) => `
  ${stateProperty}: ${color};

  &:hover {
    ${stateProperty}: ${brighten(color, stateBrightenDegree)};
  }

  &:focus {
    ${stateProperty}: ${color};
    box-shadow: 0 0 0 4px ${alpha(color, stateShadowOpacity)};
  }

  &:active {
    ${stateProperty}: ${brighten(color, stateBrightenDegree)};
    box-shadow: 0 0 0 4px ${alpha(color, stateShadowOpacity)};
  }
`

const StyledPlainButton = styled(ButtonBase)`
  position: relative;
  border: 0;
  background: transparent;
  border-radius: 0;
  font-size: ${FONT.SIZE.M};
  min-height: 0;

  &:after {
    content: '';
    position: absolute;
    top: -${GTR.XS};
    bottom: -${GTR.XS};
    left: -${GTR.XS};
    right: -${GTR.XS};
  }

  // override responsive padding
  && {
    padding: 0;
  }

  &:focus {
    outline: 1px dashed ${COLOR.ACCENT.SECONDARY};
  }

  &:active {
    outline: 1px dashed ${COLOR.ACCENT.PRIMARY};
  }

  &:disabled {
    color: unset;
    background: unset;
  }
`

// eslint-disable-next-line
const PlainButton = (props) => <StyledPlainButton {...props} />
// PlainButton.propTypes = ButtonBase.propTypes
// PlainButton.defaultProps = ButtonBase.defaultProps

const StyledPrimaryButton = styled(ButtonBase)`
  color: ${COLOR.GREY.DARK};
  ${buttonStyles(COLOR.ACCENT.PRIMARY, -0.2, 0.2)};
`

// eslint-disable-next-line
const PrimaryButton = (props) => <StyledPrimaryButton {...props} />
// PrimaryButton.propTypes = ButtonBase.propTypes
// PrimaryButton.defaultProps = ButtonBase.defaultProps

const StyledSecondaryButton = styled(ButtonBase)`
  color: ${COLOR.WHITE};
  ${buttonStyles(COLOR.ACCENT.SECONDARY, 0.3, 0.15)};

  &:before {
    filter: invert(1);
  }
`

// eslint-disable-next-line
const SecondaryButton = (props) => <StyledSecondaryButton {...props} />
// SecondaryButton.propTypes = ButtonBase.propTypes
// SecondaryButton.defaultProps = ButtonBase.defaultProps

const StyledWhiteButton = styled(ButtonBase)`
  color: ${COLOR.BLACK};
  ${buttonStyles(COLOR.WHITE, -0.1, 0.3)};
  &:disabled {
    background: ${alpha(COLOR.WHITE, 0.6)};
  }
`

// eslint-disable-next-line
const WhiteButton = (props) => <StyledWhiteButton {...props} />
WhiteButton.propTypes = ButtonBase.propTypes
WhiteButton.defaultProps = ButtonBase.defaultProps

const Button = PlainButton
Button.Primary = PrimaryButton
Button.Secondary = SecondaryButton
Button.White = WhiteButton

export default Button
