import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import responsiveCssProps from 'styles/helpers/responsiveProps'
import Wrapper from 'components/common/Wrapper'

const StyledGridItem = styled((props) => (
  <Wrapper
    omitProps={{
      responsiveKeys: ['span', 'startColumn', 'spanRows', 'startRow'],
    }}
    // eslint-disable-next-line
    {...props}
  />
))`
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'span',
      output: (span) =>
        `
          grid-column-end: span ${span};
          flex-basis: ${(100 * span) / 12}%;
        `,
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'startColumn',
      cssProperty: 'grid-column-start',
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'spanRows',
      output: (span) => `grid-row-end: span ${span};`,
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'startRow',
      cssProperty: 'grid-row-start',
    })}
`

const GridItem = ({ children, ...props }) => (
  // eslint-disable-next-line
  <StyledGridItem {...props}>{children}</StyledGridItem>
)

GridItem.propTypes = {
  children: PropTypes.node,
}

GridItem.defaultProps = {
  span: 12,
}

StyledGridItem.propTypes = GridItem.propTypes
StyledGridItem.defaultProps = GridItem.defaultProps

export default GridItem
