import React from 'react'

const iconPaths = [
  {
    key: 'Heart',
    name: 'heart',
    viewBox: '0 0 29 27',
    paths: (
      <path d="M12.941 25.755l.738.565c.234.166.502.266.804.299h.134c.302 0 .604-.1.838-.299l.436-.332C20.786 22.168 29 15.758 29 8.518 29 4.034 25.312.381 20.786.381c-2.48 0-4.76 1.063-6.303 2.89C12.941 1.476 10.628.38 8.213.38 3.689.38 0 4.034 0 8.518c.034 7.174 8.113 13.484 12.941 17.237zM8.214 3.038c2.112 0 4.09 1.229 4.995 3.122l.067.133c.235.531.838.83 1.408.764a1.38 1.38 0 001.04-.764l.1-.2a5.53 5.53 0 014.962-3.088c3.051 0 5.532 2.457 5.532 5.48 0 5.779-7.476 11.757-11.768 15.112-4.157-3.189-11.834-9.2-11.834-15.079 0-3.022 2.48-5.48 5.498-5.48z" />
    ),
  },
  {
    key: 'FullHeart',
    name: 'full-heart',
    viewBox: '0 0 29 27',
    paths: (
      <path d="M12.941 25.755l.738.565c.234.166.502.266.804.299h.134c.302 0 .604-.1.838-.299l.436-.332C20.786 22.168 29 15.758 29 8.518 29 4.034 25.312.381 20.786.381c-2.48 0-4.76 1.063-6.303 2.89C12.941 1.476 10.628.38 8.213.38 3.689.38 0 4.034 0 8.518c.034 7.174 8.113 13.484 12.941 17.237z" />
    ),
  },
  {
    key: 'Check',
    name: 'check',
    viewBox: '0 0 37 29',
    paths: (
      <path d="M12.997 19.255L32.199 0 37 4.931 12.997 29 0 15.968l4.918-4.932 8.079 8.22z" />
    ),
  },
  {
    key: 'Chevron',
    name: 'chevron',
    viewBox: '0 0 10 15',
    paths: (
      <path d="M2.259.3L1.925 0 1.59.3.415 1.356 0 1.728l.415.372 5.652 5.073-5.652 5.072-.415.373.415.372 1.176 1.056.334.3.334-.3 7.243-6.501.414-.372-.414-.372L2.259.3z" />
    ),
  },
  {
    key: 'Cross',
    name: 'cross',
    viewBox: '0 0 13 14',
    paths: (
      <path d="M4.956 7L.325 11.988a1.227 1.227 0 000 1.662 1.027 1.027 0 001.543 0L6.5 8.663l4.632 4.987a1.027 1.027 0 001.543 0 1.227 1.227 0 000-1.662L8.044 7l4.631-4.988a1.227 1.227 0 000-1.662 1.027 1.027 0 00-1.543 0L6.5 5.337 1.868.35a1.027 1.027 0 00-1.543 0 1.227 1.227 0 000 1.662L4.956 7z" />
    ),
  },
  {
    key: 'Phone',
    name: 'phone',
    viewBox: '0 0 24 22',
    paths: (
      <path d="M5.645 9.494c2.38 3.85 3.965 5.39 7.93 7.701l3.17-3.08c1.62.402 3.665.953 5.306.947.547-.004 1.092.527 1.107 1.034l-.037 4.806c-.002.532-.551 1.066-1.109 1.098C8.347 22.023.447 14.35.002 1.05-.042.536.508.003 1.055 0h5.419c.598-.022 1.143.507 1.273.83-.14 1.803.648 4.002 1.071 5.584l-3.172 3.08z" />
    ),
  },
  {
    key: 'LinkedIn',
    name: 'linkedin',
    viewBox: '0 0 21 22',
    paths: (
      <path d="M2.643 0A2.641 2.641 0 000 2.64C0 4.1 1.183 5.31 2.64 5.31c1.459 0 2.643-1.21 2.643-2.668A2.64 2.64 0 002.643 0zm12.892 7c-2.219 0-3.489 1.16-4.098 2.314h-.064V7.311H7V22h4.557v-7.271c0-1.916.144-3.768 2.515-3.768 2.337 0 2.371 2.185 2.371 3.889V22H21v-8.068C21 9.984 20.151 7 15.535 7zM.363 7.31V22h4.56V7.31H.364z" />
    ),
  },
  {
    key: 'Twitter',
    name: 'twitter',
    viewBox: '0 0 22 22',
    paths: (
      <path d="M22 3.942c-.824.365-1.65.641-2.567.734.916-.551 1.65-1.468 2.016-2.478-.917.552-1.834.917-2.84 1.103-.917-.917-2.109-1.468-3.39-1.468a4.498 4.498 0 00-4.495 4.49c0 .37 0 .734.093 1.01-3.76-.183-7.057-1.926-9.26-4.676C1.1 3.3.918 4.125.918 4.948c0 1.561.823 2.936 2.016 3.76-.734-.093-1.465-.276-2.016-.551v.093c0 2.198 1.557 4.032 3.573 4.4-.365.09-.73.183-1.189.183-.275 0-.551 0-.827-.093.552 1.833 2.202 3.119 4.218 3.119-1.557 1.192-3.484 1.923-5.593 1.923-.365 0-.734 0-1.099-.09a12.843 12.843 0 006.875 2.016c8.343 0 12.833-6.875 12.833-12.833v-.552c.917-.64 1.651-1.464 2.292-2.38z" />
    ),
  },
  {
    key: 'Instagram',
    name: 'instagram',
    viewBox: '0 0 22 22',
    paths: (
      <path d="M6.469 0C2.918 0 0 2.914 0 6.469v9.062C0 19.082 2.914 22 6.469 22h9.062C19.082 22 22 19.086 22 15.531V6.47C22 2.918 19.086 0 15.531 0H6.47zm0 2h9.062A4.463 4.463 0 0120 6.469v9.062A4.463 4.463 0 0115.531 20H6.47A4.463 4.463 0 012 15.531V6.47A4.463 4.463 0 016.469 2zm10.437 2.188a.902.902 0 00-.906.906c0 .504.402.906.906.906a.902.902 0 00.907-.906.902.902 0 00-.907-.907zM11 5c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 2c2.223 0 4 1.777 4 4s-1.777 4-4 4-4-1.777-4-4 1.777-4 4-4z" />
    ),
  },
  {
    key: 'Cookie',
    name: 'cookie',
    viewBox: '0 0 43 43',
    paths: (
      <path d="M36.173 14.466a1.458 1.458 0 11-.755-2.817 1.458 1.458 0 01.755 2.817zM4.53 25.963c-1.228-4.585-.583-9.357 1.82-13.438A17.247 17.247 0 0117.1 4.479a1.46 1.46 0 01.998.107c.266.131.504.347.648.605a4.66 4.66 0 003.083 2.277 1.46 1.46 0 011.142 1.572c-.347 3.427 2.82 5.755 5.811 4.953l.244-.094.227-.094a1.457 1.457 0 011.92.935c.61 2 2.487 3.354 4.564 3.293 1.602-.047 2.945 1.253 3.004 2.9.272 8.159-5.018 15.325-12.866 17.428-9.273 2.485-18.848-3.077-21.346-12.398zM16.844 7.585a14.333 14.333 0 00-7.982 6.42 14.516 14.516 0 00-1.515 11.203C9.427 32.976 17.4 37.612 25.12 35.544c6.53-1.75 10.933-7.719 10.706-14.514l-.043-.09c-2.859.093-5.509-1.5-6.838-3.996-3.84.707-7.648-1.652-8.674-5.483a7.272 7.272 0 01-.235-1.497 7.608 7.608 0 01-3.192-2.379zm8.35 16.616a2.187 2.187 0 104.226-1.132 2.187 2.187 0 00-4.226 1.132zm-8.415-6.049a2.188 2.188 0 11-1.133-4.226 2.188 2.188 0 011.133 4.226zM14.302 28.63a2.187 2.187 0 104.226-1.132 2.187 2.187 0 00-4.226 1.132zm7.508-5.786a1.458 1.458 0 11-.754-2.817 1.458 1.458 0 01.754 2.817zM10.82 21.26a1.458 1.458 0 102.817-.755 1.458 1.458 0 00-2.817.755zM25.645 8.983a2.188 2.188 0 104.226-1.133 2.188 2.188 0 00-4.226 1.133zm-1.57 22.312a1.459 1.459 0 11-.754-2.818 1.459 1.459 0 01.755 2.818z" />
    ),
  },
]

export default iconPaths
