import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { GTR, COLOR, BORDER, CONTENT_OUTER_WIDTH } from 'styles/tokens'
import { screenMin } from 'styles/helpers/responsive'
import responsiveCssProps from 'styles/helpers/responsiveProps'
import filterProps from 'styles/utils/filterProps'

const formatValue = (value) =>
  typeof value === 'number' ? `${value}px` : GTR[value] || value

const notUndefOr = (v1, v2) => (v1 !== undefined ? v1 : v2)

const formatValues = (values) => {
  return typeof values === 'object'
    ? values.map(formatValue).join(' ')
    : formatValue(values)
}

const elementStyles = css`
  ${({ container, containerPaddingTop, containerPaddingBottom }) =>
    container &&
    css`
      max-width: ${CONTENT_OUTER_WIDTH};
      margin: 0 auto;
      padding: ${formatValues([
        notUndefOr(containerPaddingTop, 'L'),
        'M',
        notUndefOr(containerPaddingBottom, 'L'),
      ])};
      ${screenMin.m`
        padding: ${formatValues([
          notUndefOr(containerPaddingTop, 'XL'),
          'L',
          notUndefOr(containerPaddingBottom, 'XL'),
        ])};
      `}
      ${screenMin.l`
        padding: ${formatValues([
          notUndefOr(containerPaddingTop, 'XXL'),
          'XL',
          notUndefOr(containerPaddingBottom, 'XXL'),
        ])};
      `}
    `}
  ${({ separator }) =>
    separator &&
    css`
      margin: ${formatValues(['M', 0])};
      ${screenMin.m`
        margin: ${formatValues(['L', 0])};
      `}
      ${screenMin.l`
        margin: ${formatValues(['XL', 0])};
      `}
    `}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'margin',
      cssProperty: 'margin',
      formatValues,
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'padding',
      cssProperty: 'padding',
      formatValues,
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'bordered',
      cssProperty: 'border',
      formatValues: (border) => (border ? `1px solid ${COLOR.GREY.LIGHT}` : ''),
    })}
  ${(props) =>
    responsiveCssProps({
      props,
      propName: 'centered',
      cssProperty: 'text-align',
      formatValues: (center) => (center ? 'center' : 'left'),
    })}
  ${({ background }) => background && `background: ${background};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${formatValue(maxWidth)};`}
  ${({ borderRadius }) =>
    borderRadius && `border-radius: ${BORDER.RADIUS[borderRadius]};`}
`

const StyledWrapper = styled(({ omitProps = {}, tag, ...props }) => {
  const filteredProps = filterProps({
    props,
    keys: [
      'container',
      'containerVerticalPadding',
      'containerPaddingTop',
      'containerPaddingBottom',
      'separator',
      'background',
      'maxWidth',
      'borderRadius',
      'bumpSpecificity',
      ...(omitProps.keys || []),
    ],
    responsiveKeys: [
      'margin',
      'padding',
      'bordered',
      'centered',
      ...(omitProps.responsiveKeys || []),
    ],
  })
  const Element = tag
  // eslint-disable-next-line
  return <Element {...filteredProps} />
})`
  ${({ bumpSpecificity }) =>
    bumpSpecificity
      ? css`
          && {
            ${elementStyles}
          }
        `
      : elementStyles};
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`

const Wrapper = ({
  children,
  containerVerticalPadding,
  containerPaddingTop,
  containerPaddingBottom,
  ...props
}) => {
  if (containerVerticalPadding)
    console.warn(
      'The `containerVerticalPadding` prop on <Wrapper> has been deprecated in favour of `containerPaddingTop` and `containerPaddingBottom`, see https://github.com/farewill/ui/pull/57 for more details.'
    )

  return (
    <StyledWrapper
      containerPaddingTop={notUndefOr(
        containerPaddingTop,
        containerVerticalPadding
      )}
      containerPaddingBottom={notUndefOr(
        containerPaddingBottom,
        containerVerticalPadding
      )}
      // eslint-disable-next-line
      {...props}
    >
      {children}
    </StyledWrapper>
  )
}

Wrapper.propTypes = {
  /** By default every additional prop becomes an html attribute if not ommited using this object. This should be used when extending Wrapper, see Grid for a good example */
  omitProps: PropTypes.shape({
    keys: PropTypes.array,
    responsiveKeys: PropTypes.array,
  }),
  /** Used when extending Wrapper to prioritise the Wrapper styles over the extended component's styles, for example a Heading with a margin prop */
  bumpSpecificity: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired,
  container: PropTypes.bool,
  /** Deprecated */
  containerVerticalPadding: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]), // todo: deprecate in next major release
  containerPaddingTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  containerPaddingBottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  separator: PropTypes.bool,
  background: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOf(['S', 'M']),
}

Wrapper.defaultProps = {
  tag: 'div',
}

StyledWrapper.propTypes = Wrapper.propTypes
StyledWrapper.defaultProps = Wrapper.defaultProps

export default Wrapper
