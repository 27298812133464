import { css } from 'styled-components'
import { screenMax } from 'styles/helpers/responsive'

import { FONT, GTR } from '../tokens'

export const paragraph = css`
  line-height: 1.7;
  font-size: ${FONT.SIZE.M};
  margin-bottom: ${GTR.S};
`

export const headingXL = css`
  font-size: ${FONT.SIZE.XXXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  line-height: 1.25;
  margin-bottom: ${GTR.M};
  letter-spacing: -1.56px;

  ${screenMax.s`
    font-size: ${FONT.SIZE.XL};
  `}
`

export const headingL = css`
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  line-height: 1.25;
  margin-bottom: ${GTR.M};
  letter-spacing: -1.44px;

  ${screenMax.s`
    font-size: 22px;
    letter-spacing: 0;
  `}
`

export const headingM = css`
  font-size: ${FONT.SIZE.XL};
  font-weight: ${FONT.WEIGHT.BOLD};
  line-height: 1.25;
  margin-bottom: ${GTR.M};
  letter-spacing: -1.08px;

  ${screenMax.s`
    font-size: 20px;
    letter-spacing: 0;
  `}
`

export const headingS = css`
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  line-height: 1.25;
  margin-bottom: ${GTR.S};

  ${screenMax.s`
    font-size: ${FONT.SIZE.M};
    letter-spacing: 0;
  `}
`

export const headingXS = css`
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.BOLD};
  line-height: 1.333;
  margin-bottom: ${GTR.S};
`
