import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { COLOR, FONT, GTR } from 'styles/tokens'
import { screenMax, screenMin } from 'styles/helpers/responsive'

import { Button, Wrapper } from 'components/common'
import SavedJobs from 'components/SavedJobs'
import logoWhite from 'images/pixel-pond-logo-white.png'
import PATHS from 'routes/paths'

import Icon from 'components/Icon'

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${screenMax.m`
    padding: ${GTR.XS} ${GTR.M};
    display: block;
    font-size: ${FONT.SIZE.L};
  `}
`

const StyledLinkWrapper = styled.span`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-decoration: none;
  font-size: ${FONT.SIZE.M};

  &:hover {
    text-decoration: none;
  }

  ${screenMin.m`
    &:not(:last-of-type) {
      margin-right: ${GTR.L};
    }
  `}
`

const StyledHeader = styled.header`
  background-color: ${COLOR.GREY.DARK};

  ${({ $light }) =>
    $light &&
    css`
      background-color: transparent;

      ${screenMax.m`
        background-color: ${COLOR.ACCENT.PRIMARY};
      `}
    `};

  ${screenMax.m`
      ${({ $open }) =>
        $open &&
        `
      background-color: ${COLOR.ACCENT.PRIMARY};
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    `}
  `}
`

const StyledLogoLink = styled(Link)`
  font-size: 0;
  margin-right: ${GTR.M};
`

const StyledWrapper = styled.div`
  padding: ${GTR.M} 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${screenMax.m`
    padding: ${GTR.S} 0;
  `}
`

const StyledLinks = styled.div`
  display: flex;
  align-items: center;

  ${StyledLink} {
    color: ${({ $light }) => ($light ? COLOR.GREY.DARK : COLOR.WHITE)};

    ${screenMin.l`
      &:hover {
        color: ${COLOR.ACCENT.PRIMARY};
      }
    `}
  }

  ${screenMax.m`
    display: none;
    width: calc(100% + ${parseInt(GTR.M, 10) * 2}px);

    ${({ $open }) =>
      $open &&
      `
      width: 100%;
      position: fixed;
      top: 76px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-top: ${GTR.S};
      padding-bottom: ${GTR.S};
      z-index: 100;
      background-color: ${COLOR.ACCENT.PRIMARY};

      ${StyledLink} {
        color: ${COLOR.GREY.DARK};
      }
    `}
  `}
`

const StyledMenuButton = styled(Button)`
  display: none;
  font-size: ${FONT.SIZE.M};
  color: ${COLOR.WHITE};

  && {
    padding: ${GTR.XS};
  }

  ${screenMax.m`
    display: block;
    
    ${({ $open }) => $open && `color: ${COLOR.GREY.DARK};`}
  `}

  ${({ $light }) =>
    $light &&
    `
      color: ${COLOR.GREY.DARK};
    `}
`

const StyledLogoImage = styled.img`
  ${screenMax.m`
    width: 44px;
  `}
`

const StyledSavedJobs = styled(StyledLinkWrapper)`
  ${screenMax.m`
    margin-top: auto;
    margin-bottom: ${GTR.XL};
    padding: 0 ${GTR.M};
  `}
`

const StyledCrossIcon = styled(Icon.Cross)`
  margin-left: ${GTR.XXS};
  transform: translateY(1px);
`

const Header = ({ light }) => {
  const [open, setOpen] = useState(false)
  return (
    <StyledHeader $light={light} $open={open}>
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledWrapper>
          <StyledLogoLink to="/">
            Pixel Pond
            <StyledLogoImage src={logoWhite} alt="Pixel Pond" width={90} />
          </StyledLogoLink>
          <StyledMenuButton
            type="button"
            onClick={() => setOpen(!open)}
            $light={light}
            $open={open}
          >
            {open ? 'Close' : 'Menu'} {open && <StyledCrossIcon width={12} />}
          </StyledMenuButton>
          <StyledLinks $open={open} $light={light}>
            <StyledLinkWrapper>
              <StyledLink to={PATHS.JOBS}>Search jobs</StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink to={PATHS.ABOUT}>About us</StyledLink>
            </StyledLinkWrapper>
            {/* <StyledLinkWrapper>
              <StyledLink to={PATHS.BLOG}>Blog</StyledLink>
            </StyledLinkWrapper> */}
            <StyledLinkWrapper>
              <StyledLink to={PATHS.EMPLOYERS}>Employer’s guide</StyledLink>
            </StyledLinkWrapper>
            <StyledLinkWrapper>
              <StyledLink to={PATHS.CONTACT}>Contact us</StyledLink>
            </StyledLinkWrapper>
            <StyledSavedJobs>
              <SavedJobs light={light} />
            </StyledSavedJobs>
          </StyledLinks>
        </StyledWrapper>
      </Wrapper>
    </StyledHeader>
  )
}

Header.propTypes = {
  light: PropTypes.bool,
}

Header.defaultProps = {
  light: false,
}

export default Header
